import React from 'react';
import Bannerhome1 from './../../assets/images/form_dpf/dpf-form.png';
import Santander from './../../assets/images/form_dpf/santander-logo-rojo.svg';
import './FormDepositFixed.scss';

const BannerFormDpf: React.FC = () => {
  return (
    <div className="dpf-container-banner" >
      <div>
        <center>
          <span className="dpf-text-banner">¡Solícita tu Depósito a Plazo Fijo!<br /></span>
          <p className="description-banner-dft">Cumple tus metas con nuestra súper tasa</p>
        </center>
      </div>
      <div>
        <center>
          <img className="dpf-image"  src={Bannerhome1} alt="Alt_cumple tus metas con nuestra súper tasa ahorros inversión SURGIR depósito a plazo fijo formulario de solicitud" />
        </center>
      </div>
      <br />
      <br />
      <div>
        <center>
          <span className="banner-text" >Con el respaldo de</span>
          <br />
          <img src={Santander} />
        </center>
      </div>
    </div>
  );
}

export default BannerFormDpf;
