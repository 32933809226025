import SurgirBanner from "components/ui/SurgirBanner/SurgirBanner";
import SurgirButton from "components/ui/SurgirButton/SurgirButton";
import SurgirCarousel from "components/ui/SurgirCarousel/SurgirCarousel";
import SurgirDownloadIcon from "components/ui/SurgirDownloadIcon/SurgirDownloadIcon";
import SurgirPrincipalTitle from "components/ui/SurgirPrincipalTitle/SurgirPrincipalTitle";
import SurgirSlideSantander from "components/ui/SurgirSlideSantander/SurgirSlideSantander";
import LinkRoutes from "constants/LinkRoutes";
import React, { useEffect, useState } from "react";
import { Accordion, Container, Tab, Tabs } from "react-bootstrap";
import { goTopTab } from "utils/functions";
import BenefitsDPFF from '../../assets/images/flexibleDeposit/benefits-dpff.png';
import HeroSimulator from '../../assets/images/flexibleDeposit/hero-simulator.png';
import HeroSteps from '../../assets/images/flexibleDeposit/hero-steps.png';
import { STEPS_DPFF } from "./FlexibleFixedDeposit.fixture";
import './FlexibleFixedDeposit.scss';
import SurgirTabs from "components/ui/SurgirTabs/SurgirTabs";
import SurgirAccordion from "components/ui/SurgirAccordion/SurgirAccordion";

const FlexibleFixedDeposit: React.FC = () => {

  const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener("resize", listener)
    }, [matches, query]);

    return matches;
  }

  const isTablet1 = useMediaQuery('(min-width: 361px) and (max-width: 576px)');
  const isMobile = useMediaQuery('(max-width: 360px)');
  const mobileTab = useMediaQuery('(max-width: 767px)');
  const [keyTabInfo, setKeyTabInfo] = useState("Requisitos");

  const goTabDocument = () => {
    goTopTab('section-tab-dpff');
    setKeyTabInfo('Documentos');
  }

  const frequentQuestionsPART1 = [
    {
      title: '¿Qué es un Depósito a Plazo Fijo Flexible?',
      body: <p>
      Es un Depósito a Plazo que brinda flexibilidad para realizar incrementos y retiros en el capital. Este beneficio especial es para las personas naturales que deseen ahorrar en soles a un plazo determinado en SURGIR.
    </p>
    },
    {
      title: '¿Qué necesito para abrir mi Depósito a Plazo Fijo Flexible en SURGIR?',
      body: <p>
      Solo necesitas presentar tu Documento de Identidad y el depósito del monto mínimo de apertura.
      </p>
    },
    {
      title: '¿Puedo incrementar el capital de mi Depósito a Plazo Fijo Flexible?',
      body: <div>
        <p>
          Sí, tendrás la libertad de aumentar el capital inicial para obtener mayores ganancias, de manera ilimitada podrás realizar incrementos en tu Depósito a Plazo. Podrás revisar las condiciones en los siguientes documentos:
        </p>
        <div style={{ lineHeight: "32px" }}>
          <span>• </span><span role="button" className="span-surgir" onClick={goTabDocument}>Cartilla Información</span>.<br />
          <span>• </span><span role="button" className="span-surgir" onClick={goTabDocument}>Tarifario</span> de productos pasivos.<br />
          <span>• </span><span role="button" className="span-surgir" onClick={ () => {window.open(LinkRoutes.promotionHistory, '_blank')}}>Términos y condiciones</span> de la campaña que deseas participar.
        </div>
        </div>
    }
  ]

  const benfitsFlexibleFixedDeposit = [
    {
      icon: 'savings.svg',
      title: 'Rentabiliza tu dinero',
      description: 'Te brindamos super tasas para cualquier plazo y monto.'
    },
    {
      icon: 'flexible.svg',
      title: 'Flexibilidad en tu capital',
      description: 'Realiza incrementos y decrementos sobre tu capital y te mantenemos la misma tasa.'
    },
    {
      icon: 'clock-check.svg',
      title: 'Apertura rápida y sencilla',
      description: 'Ábrelo en donde estés, al realizar una transferencia a nuestras cuentas. Solicítalo aquí.',
      link: true,
      textLink: 'Solicítalo aquí',
      hrefLink: LinkRoutes.formDepositFixed
    },
    {
      icon: 'hands-red.svg',
      title: 'Atención personalizada',
      description: 'Nuestros Ejecutivos de Cuenta te brindarán servicio personalizado y asesoría financiera.'
    },
    {
      icon: 'santander.svg',
      title: 'Seguridad y respaldo',
      description: 'Supervisados por la SBS y con el respaldo de Santander, tus ahorros estarán seguros.',
      link: true,
      textLink: 'Santander',
      hrefLink: 'https://www.santander.com.pe/'
    },
    {
      icon: 'calendar.svg',
      title: 'Flexibilidad en tus intereses',
      description: 'Recibe el abono de tus intereses de forma periódica o al vencimiento con capitalización.'
    }
  ]
  const [faqVisible, setFaqVisible] = useState(false)

  const frequentQuestionsPART2 = [
    {
      title: '¿Puedo realizar retiros sin penalidad?',
      body: <p>
      Durante la vigencia de tu Depósito a Plazo Fijo Flexible puedes realizar hasta 3 retiros o decrementos de capital debiendo conservar el saldo mínimo de apertura y te daremos el beneficio de mantener tu tasa de apertura.
    </p>
    },
    {
      title: '¿Cuánto paga de interés el Depósito a Plazo Fijo Flexible?',
      body: <p>
      El Depósito a Plazo Fijo Flexible te brinda una tasa de interés atractiva de acuerdo al plazo que desees escoger. Revisa aquí nuestro <span>
      <a href={process.env.REACT_APP_DPFF_TARIFARIO_TASAS} target="_blank">tarifario</a>
      </span>.
    </p>
    },
    {
      title: '¿Cómo recibo los intereses que mi Depósito a Plazo Fijo Flexible vaya generando?',
      body: <div>
        <p>
                          Puedes recibir los intereses que la cuenta vaya ganando de acuerdo a las siguientes alternativas: 
                        </p>
                        <div>
                          <ul>
                            <li>Al vencimiento con capitalización.</li>
                            <li>Periódico: mensual, bimestral, trimestral, semestral, anual o al vencimiento sin capitalización, abonándose en tu Cuenta de Ahorros de SURGIR.</li>
                          </ul>
                        </div>
      </div>
    },
    {
      title: '¿Puedo cancelar mi Depósito a Plazo Fijo Flexible antes del vencimiento del plazo pactado?',
      body: <div>
         <p>
                        Puedes cancelar tu depósito cuando quieras, al momento de la cancelación se calcularán los intereses que se deberán pagar de acuerdo a una tasa de pre-cancelación del Depósito a Plazo Fijo Flexible. Podrás revisar las condiciones en los siguientes documentos:
                        </p>
                        <div style={{ lineHeight: "32px" }}>
                          <span>• </span><span role="button" className="span-surgir" onClick={goTabDocument}>Cartilla Información</span>.<br />
                          <span>• </span><span role="button" className="span-surgir" onClick={goTabDocument}>Tarifario</span> de productos pasivos.<br />
                          <span>• </span><span role="button" className="span-surgir" onClick={ () => {window.open(LinkRoutes.promotionHistory, '_blank')}}>Términos y condiciones</span> de la campaña que deseas participar.
                        </div>
      </div>
    },
    {
      title: '¿Qué sucede con mi Depósito a Plazo Fijo Flexible cuando llega la fecha de vencimiento?',
      body: <div>
        <p>
                          Tu depósito se renueva automáticamente de acuerdo al tarifario vigente de producto.
                        </p>
                        <p>
                          No aplica tarifarios de Campaña del producto mencionado. Si se desea acceder a la tasa de campaña, se deberá abrir un nuevo Depósito a Plazo Fijo Flexible durante el plazo de vigencia de la campaña.
                        </p>
      </div>
    },
    {
      title: '¿Me cobran comisión por mantenimiento de cuenta?',
      body:  <p>
      En SURGIR no cobramos comisión por mantener tu Depósito a Plazo Fijo Flexible.
    </p>
    },
    {
      title: '¿El Depósito a Plazo Fijo Flexible se encuentra cubierto por el Fondo de Seguro Depósito?',
      body:  <p>
      SURGIR deberá efectuar el pago de las primas que fije la Superintendencia de Banca, Seguros y AFP (SBS), durante veinticuatro (24) meses sucesivos para que sus operaciones se encuentren respaldadas por el Fondo y dar cumplimiento a lo dispuesto en el Artículo N° 3 “Nuevos Miembros” de la Resolución SBS N° 0657 - 99 y sus modificatorias.
      Por lo tanto, los Productos Pasivos en SURGIR estarán protegidos por el Fondo de seguro de depósito dentro del periodo de veinticuatro (24) meses de acuerdo con lo establecido en la normativa vigente aplicable. Más información en <a href="https://www.surgir.com.pe/" target="_blank">www.surgir.com.pe</a> o en <a href="https://fsd.org.pe/" target="_blank">www.fsd.org.pe</a>.
      </p>
    },
    {
      title: '¿Qué es la TREA?',
      body: <p>
      La TREA es la Tasa de Rendimiento Efectiva Anual y te permite saber cuánto ganarás efectivamente por el dinero que deposites al vencimiento del plazo, considerando todos los cargos por comisiones y gastos, bajo el supuesto de cumplimiento de todas las condiciones pactadas.
      </p>
    },
    {
      title: '¿Cuál es el procedimiento aplicable en el caso de fallecimiento del titular de la cuenta?',
      body: <div>
        <div>
                          <p><span>Por Sucesión Testada:</span></p>
                          <ul>
                            <li>Partida de defunción en original o copia.</li>
                            <li>Copia legalizada notarial del testamento.</li>
                            <li>Copia literal original del Testamento inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                            <li>Copia del documento de identidad de cada solicitante. En caso de menores de edad, deberá proporcionar la Partida de nacimiento y el documento de identidad.</li>
                          </ul>
                        </div>

                        <div>
                          <p><span>Por Sucesión Intestada:</span></p>
                          <p><span>Notarial:</span></p>
                          <ul>
                            <li>Copia legalizada del Acta de la Sucesión Intestada (que declara herederos del causante) o la protocolización notarial de Declaratoria de Herederos.</li>
                            <li>Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                            <li>Copia del documento de identidad de cada solicitante. En caso de menores de edad, deberá proporcionar la Partida de nacimiento y el documento de identidad.</li>
                          </ul>

                          <p><span>Judicial:</span></p>
                          <ul>
                            <li>Copia certificada por el Poder Judicial de la Resolución de Sucesión Intestada emitida por el juzgado respectivo (que declare los herederos del causante).</li>
                            <li>Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el supuesto de que exista heredero menor de edad existen dos opciones presentar una de ellas dependiendo su caso:</span>
                          </p>
                          <ul>
                            <li>Copia certificada por el Poder Judicial de la Resolución de División y Partición y de la Resolución que la declare consentida.</li>
                            <li>Copia certificada por el Poder Judicial de la Resolución Judicial que autoriza a disponer los fondos del menor, y de la Resolución que la declare consentida, para los casos que se requiera disponer de los fondos que le corresponda al menor.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el caso que uno de los herederos declarados haya fallecido:</span>
                          </p>
                          <ul>
                            <li>Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el supuesto que uno de los solicitantes actúe mediante poder:</span>
                          </p>
                          <ul>
                            <li>Copia literal original de otorgamiento de poder o vigencia de poder, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>

                        <div>
                          <p>
                            <span>En el caso que el fallecido era casado con separación de bienes:</span>
                          </p>
                          <ul>
                            <li>Copia literal original de la separación de patrimonios si la hubiese, con una antigüedad no mayor a 15 días emitida por SUNARP.</li>
                          </ul>
                        </div>
      </div>
    },
    {
      title: '¿Cuál es el procedimiento de cancelación de la cuenta?',
      body: <div>
        <p>
                          El cliente deberá solicitar la cancelación de su cuenta contactándose con su Ejecutivo de Cuenta, acercándose a la Oficina Especial Compartida o comunicándose a través del buzón atencionahorros@surgir.com.pe, presentando los siguientes documentos:
                        </p>
                        <ul>
                          <li>La <span role="button" className="span-surgir" onClick={goTabDocument}>Carta de Instrucción</span> llenada dirigida a la Financiera y firmada por los titulares de la cuenta.</li>
                          <li>Adjuntar la copia de DOI (documento oficial de identidad) del (los) titular(es).</li>
                        </ul>
                        <p>
                          <span>Importante:</span> Si deseas transferir tu dinero a otro banco deberás indicar el número y código interbancario de la cuenta de ahorros de recepción de dinero, esta cuenta deberá tener la misma titularidad del Depósito a Plazo Fijo en SURGIR. En caso de enviar la carta al buzón, colocar como asunto: Cancelación de Depósito a Plazo Fijo y/o Cuenta de Ahorros.
                        </p>

                        <p>
                          Para más información contactarse con su Ejecutivo de Cuenta.
                        </p>
      </div>
    },
    {
      title: '¿Dónde puedo solicitar más información para abrir mi cuenta?',
      body:  <ul>
      <li>Solicita un <span>Depósito a Plazo Fijo</span> en nuestro <a target='_blank' href={LinkRoutes.formDepositFixed}>formulario</a> y nos comunicaremos contigo.</li>
      <li><span>Oficina Especial Compartida:</span> Cl. Amador Merino Reina 411, San Isidro.</li>
      {/* <li><span>Central telefónica:</span> <a href="tel:(01) 480 0123"
        rel="noopener noreferrer">(01) 480 0123.</a>
      </li> */}
    </ul>
    },
  ]

  const TABS_ABOUT_DPFF = [
    {
      title: 'Requisitos',
      body: (
        <div className="tab-requeriments">
          <span>Persona Natural</span>
          <ul>
            <li>Presentar original y copia de tu Documento Oficial de Identidad (DOI).</li>
          </ul>
          <p>En caso actúe por medio de un representante o apoderado deberá proporcionarse:</p>
          <ul>
            <li>Vigencia de poder (no mayor a 30 días) del apoderado.</li>
            <li>Copia del Documento de Identidad del apoderado.</li>
          </ul>
        </div>
      )
    },
    {
      title: 'Documentos',
      body: (
        <div className="tab-documents">
          <div>
            <SurgirDownloadIcon
              text={'Documentos Contractuales - Cartilla (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_CONTRACTUAL_CARTILLA}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Documentos Contractuales - Contrato (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_CONTRACTUAL_CONTRATO}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Tarifario de tasas (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_TARIFARIO_TASAS}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Tarifario de comisiones (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_TARIFARIO_COMISIONES}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Fórmulas y ejemplos explicativos (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_FORMULAS_EJEMPLOS}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Documento o sección de Beneficios, Riesgos y Condiciones del Producto (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_BENEFICIOS_CONDICIONES}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Solicitud de Apertura (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_SUPERSEGURO_CERTIFICADO}
              underline={true}
            />
          </div>
          <div>
            <SurgirDownloadIcon
              text={'Carta de Instrucción (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_CARTA_INSTRUCCION}
              underline={true}
            />
          </div>
          {/* <div>
            <SurgirDownloadIcon
              text={'Términos y condiciones - Depósito a Plazo Fijo (PDF/560 KB)'}
              href={process.env.REACT_APP_DPFF_SUPERSEGURO_CERTIFICADO}
              underline={true}
            />
          </div> */}
        </div>
      )
    },
    {
      title: 'Preguntas frecuentes',
      body: (
        <div className="tab-faq">
          <Accordion>
            <SurgirAccordion sections={frequentQuestionsPART1}/>  
            
            {
              !faqVisible ? (

                <div className="section-information__tabs__link">
                  <button className="section-information__tabs__link__body" onClick={() => setFaqVisible(true)} >
                    <h6 >Ver más preguntas</h6>
                    <span>
                      <img src="icons/safe/chevron-down-sm.svg" alt="super seguro" />
                    </span>
                  </button>
                </div>

              ) : (
                  <div className="mt-2">
                    <SurgirAccordion sections={frequentQuestionsPART2}/>
                    <div className="section-information__tabs__link">
                      <button className="section-information__tabs__link__body" onClick={() => setFaqVisible(false)} >
                        <h6 >Ver menos preguntas</h6>
                        <span>
                          <img src="icons/safe/chevron-up-sm.svg" alt="super seguro" />
                        </span>
                      </button>
                    </div>

                  </div>
              )
            }
            
          </Accordion>
        </div>
      )
    },
    {
      title: 'Solicítalo aquí',
      body: (
        <div className="tab-solicitalo">
          <p style={{marginBottom: '25px'}}>Solicita tu Depósito a Plazo Fijo Flexible a través de nuestros canales:</p>
          <div className="tab-solicitalo__cards">
            <div className="tab-solicitalo__cards__card" >
              <div className="tab-solicitalo__cards__card__icon">
                <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/solicitalo-form.svg'} />
              </div>
              <div className="tab-solicitalo__cards__card__text">
                <p >
                  Completa nuestro <span className="span-surgir"><a href={LinkRoutes.formDepositFixed} target="_blank">formulario</a></span> y nos comunicaremos contigo.
                </p>
              </div>
            </div>
            <div className="tab-solicitalo__cards__card" >
              <div className="tab-solicitalo__cards__card__icon">
                <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/solicitalo-point.svg'} />
              </div>
              <div className="tab-solicitalo__cards__card__text">
                <p>
                  Oficina Especial Compartida: Cl. Amador Merino Reina 411, San Isidro.
                </p>
              </div>
             
            </div>
            {/* <div className="tab-solicitalo__cards__card" >
              <div className="tab-solicitalo__cards__card__icon">
                <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/solicitalo-phone.svg'} />
              </div>
             <div className="tab-solicitalo__cards__card__text">
              <p >
                Central Telefónica: <span className="span-surgir"><a href="tel:(01) 480 0123">(01) 480 0123</a></span>
              </p>
             </div>
            </div> */}
          </div>
        </div>
      )
    },
    {
      title: 'Simulador',
      body: (
        <div className="tab-simulador">
          <p>¡Entérate cuantos intereses ganarás con nuestras súper tasas!</p>
          <div>
            <SurgirDownloadIcon
              text={'Simulador Depósito a Plazo Fijo Flexible (XLSX/560 KB)'}
              href={process.env.REACT_APP_DPFF_SIMULADOR}
              underline={true}
            />
          </div>
        </div>
      )
    }
  ]

  

  const redirectForm = () => {
    window.open(LinkRoutes.formDepositFixed, '_blank')
  };

  const redirectSimulatorDpff = () => {
    window.open(process.env.REACT_APP_DPFF_SIMULADOR, '_blank')
  }

  return (
    <div className="dpff">
      
      <SurgirBanner 
        title={'Depósito a Plazo Fijo Flexible'}
        description={'Realiza incrementos y retiros de tu capital ¡y te mantenemos la misma tasa!'}
        image={'banner-dpff-desktop.png'}
        imageFooter={'logo-santander-red.svg'}
        showButton={true}
        textButton="Solicítalo ahora"
        showFooter={true}
        backgroundColor={'#FFFFFF'}
        eventClickButton={redirectForm}
        altImgBanner="Alt_metas ahorros inversión rentabilidad SURGIR landing depósito a plazo fijo flexible"
      />
      
      <div className="dpff-benefits">
        <Container>
          <div className='titleTablet2'>
            <SurgirPrincipalTitle
              text={'Haz crecer tus ahorros de forma segura y rentable en SURGIR'}
              alignText="center"
            />
          </div>

          <div className="dpff-benefits__body">
            <div className="dpff-benefits__body__hero">
              <img alt="Depósito a Plazo Fijo Flexible" src={BenefitsDPFF} />
            </div>
           
            <div className="dpff-benefits__body__content">
              <div className="dpff-benefits__body__content__title">
                <SurgirPrincipalTitle 
                  text={'Haz crecer tus ahorros de forma segura y rentable en SURGIR'} 
                  alignText="left"
                />
              </div>
              
              <p>
              Abre tu Depósito a Plazo Fijo Flexible 
              <strong> desde S/ 80,000 </strong> 
              con la flexibilidad de realizar abonos o retiros de capital.
              </p>

              <div className="dpff-benefits__body__content__list">
                <ul>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/estadistica.svg'} />
                    <span>Hasta <span className="dpff-benefits__body__content__list__strong-red">6.60 % TREA</span> en soles.</span>
                  </li>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/out-dolar.svg'} />
                    <span>Hasta 3 retiros de capital manteniendo la misma tasa.</span>
                  </li>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/retry.svg'} />
                    <span>Incrementos de capital ilimitados durante los dos primeros años.</span>
                  </li>
                  <li>
                    <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/dolar.svg'} />
                    <span>Sin costo de mantenimiento sin importar el saldo de tu cuenta.</span>
                  </li>
                </ul>
              </div>

              <div className="dpff-benefits__body__content__button">
                <SurgirButton
                  variant="danger"
                  radius="32px"
                  width={"130px"}
                  height={"46px"}
                  fontFamily={'"Str-Microtext-Bold", sans-serif'}
                  marginBottom='0px'
                  marginTop='16px'
                  onClick={redirectForm}
                >
                  ¡Lo quiero!
                </SurgirButton>
              </div>
            </div>
          
          </div>
        </Container>
      </div>

      <div className="dpff-benefits-carousel">
        <div>
          <SurgirPrincipalTitle text="Descubre los beneficios que tenemos para ti" />

          <div>
            <SurgirCarousel 
              items={benfitsFlexibleFixedDeposit}
            />
          </div>
        </div>
      </div>

      <div>
        <SurgirSlideSantander/>
      </div>

      <div className="dpff-steps">
        <div className="dpff-steps__header">
          <SurgirPrincipalTitle  
            text={'¡Solicita tu Depósito a Plazo Fijo Flexible fácil y rápido!'}
          />

          <h5>Solicítalo en 3 simples pasos.</h5>
        </div>
        <Container>
          <div className="dpff-steps__content">
            <div className="dpff-steps__content__image">
              <img src={HeroSteps} />
            </div>
            <div className="dpff-steps__content__steps">              
              <ul>
                {
                  STEPS_DPFF.map((step, index) => {
                    return (
                      <li 
                        key={step.title}
                        className="dpff-steps__content__steps__step">
                        <span className="dpff-steps__content__steps__step__index">{index + 1}</span>
                        <div>
                          <span className="dpff-steps__content__steps__step__title">{step.title}</span>
                          {
                              step.url ? (
                                <SurgirButton
                                  colorText={'#ec0000'}
                                  variant="link"
                                  marginTop={'-10px'}
                                  marginBottom={'0px'}
                                  border={'0px'}
                                  onClick={redirectForm}
                                  fontSize={'20px'}
                                >
                                  {step.link}
                                </SurgirButton>
                              ) : (
                                <SurgirButton
                                variant=""
                                downloadUrl={process.env.REACT_APP_DPFF_SUPERSEGURO_CERTIFICADO}
                                downloadStyleClass={'linkUnderline'}
                                marginTop={'-5px'}
                                marginBottom={'0px'}
                                downloadSize={'20px'}
                              >
                                {step.link}
                              </SurgirButton>
                              )
                            }
                            
                          <span>
                           

                          </span>
                          <h6 className="dpff-steps__content__steps__step__description">{step.description}</h6>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
              <div className="dpff-steps__content__steps__footer">
                <p>
                  <strong>¡Y listo! </strong> 
                  Podriamos enviarte los contratos a tu correo electrónico
                  o a tu domicilio ¡tú eliges!
                </p>
                <p>
                  <strong>¡Haz que tu dinero crezca con SURGIR!</strong>
                </p>
              </div>
              <div className="dpff-steps__content__steps__button">
                <SurgirButton
                  variant="danger"
                  radius="32px"
                  width={"167px"}
                  height={"46px"}
                  fontFamily={'"Str-Microtext-Bold", sans-serif'}
                  onClick={redirectForm}
                >
                  Solicítalo ahora
                </SurgirButton>
              </div>

            </div>
          </div>
        </Container>

      </div>

      <div className="dpff-simulation">
        <Container>
          <div className="dpff-simulation__body">
            <div className="dpff-simulation__body__text">
                <div>
                  <SurgirPrincipalTitle
                    text={'Simula tu Depósito a Plazo Fijo Flexible'}
                    subtext={'Conoce cuanto interés ganarás con nuestras súper tasas.'}
                    alignText={isTablet1 || isMobile ? 'center' : 'left'}
                  />
                </div>
                <div>
                  <SurgirButton
                    variant="danger"
                    radius="32px"
                    width={"138px"}
                    height={"46px"}
                    fontFamily={'"Str-Microtext-Bold", sans-serif'}
                    marginBottom="0px"
                    marginTop="40px"
                    onClick={redirectSimulatorDpff}
                  >
                    Simula aquí
                  </SurgirButton>
                </div>
            </div>
            <div className="dpff-simulation__body__image">
              <img alt="" src={HeroSimulator} />
            </div>
          </div>
        </Container>
      </div>

      <div className="dpff-about" id="section-tab-dpff">
        <Container>
          <SurgirPrincipalTitle 
            text={'Conoce más sobre tu Depósito a Plazo Fijo Flexible'} />
          
          <div style={{marginTop: 30}}>
            {
              !mobileTab ? <SurgirTabs tabs={TABS_ABOUT_DPFF} />
               : <div className='container-acordion-responsive'>
                  <SurgirAccordion 
                    sections={TABS_ABOUT_DPFF} 
                  />
                </div>
            }
          </div>
        </Container>
      </div>

    </div>

    
  )
}

export default FlexibleFixedDeposit