import { Box, FormHelperText } from "@mui/material";
import { SurgirSnackbarContentWrapper } from "components/ui/SurgirSnackbarContentWrapper/SurgirSnackbarContentWrapper";
import LinkRoutes from "constants/LinkRoutes";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { validateCellPhone, validateDocumentNumber, validateEmailv2, validateFieldObject, validateFieldString, validateFormDPF, validateLimitLetter } from "utils/ValidateField";
import SurgirAutocomplete from "../../components/ui/SurgirAutocomplete/SurgirAutocomplete";
import SurgirDropDown from "../../components/ui/SurgirDropDown/SurgirDropDown";
import { SurgirTextField } from "../../components/ui/SurgirTextField/SurgirTextField";
import ConditionalLink from '../../modules/components/Simulator/components/ConditionalLink';
import { apiGetFindUbigeoDep, apiGetTypeDocuments, apiPostFindUbigeo, apiPostSaveDpfForm } from "../../service/apiEndPoint";
import { PATTER_ALPH } from "../../utils/constants";
import BannerFormDpf from "../form-deposit-fixed-santander/BannerFormDpf";
import "./FormDepositFixed.scss";
import ToastError from "./ToastError";

interface ContactanosRCFProps {}

interface DocumentType {
  codeTypeDoc: string
  documentType: string
}

interface ListDepartment {
  codeDep: string
  departament: string
}

const ContactanosRCF: React.FC<ContactanosRCFProps> = (props) => {
  const [typeDocument, setTypeDocument] = useState<{ value: string; name: string } | null | undefined>(null);
  const [document, setDocument] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [department, setDepartment] = useState<{ value: string; name: string } | null>(null);
  const [province, setProvince] = useState<{ value: string; name: string } | null>(null);
  const [district, setDistrict] = useState<{ value: string; name: string } | null>(null);
  const [listDocumentType, setListDocumentType] = useState<{ value: string; name: string }[]>([]);
  const [listDepartment, setListDepartment] = useState<{ value: string; name: string }[]>([]);
  const [listProvince, setListProvince] = useState<{ value: string; name: string }[]>([]);
  const [listDistrict, setListDistrict] = useState<{ value: string; name: string }[]>([]);
  const [visibleModalToastSuccess, setVisibleModalToastSuccess] = useState(false);
  const [visibleValidation, setVisibleValidation] = useState(false);
  const [visibleValidationProvincia, setVisibleValidationProvincia] = useState(false);
  const [visibleValidationDistrito, setVisibleValidationDistrito] = useState(false);
  const [checkPolitics, setCheckPolitics] = useState(false);
  const [checkAccordance, setCheckAccordance] = useState(false);
  const [typeInput, setTypeInput] = useState("number");
  const [messageErrorToas, setMessageErrorToast] = useState<string[]>([]);
  const [showReturnSimulator, setShowReturnSimulator] = useState(false);
  const [captchaRef, setCaptcha] = useState<string | null>(null);

  const [messagesError, SetMessagesError] = useState([{}]);
  const [openSnackError, setopenSnackError] = useState(false);

  const openSnackerrorFunction = () => {
    setopenSnackError(true);
  };

  
  const [validateFormError, setValidateError] = useState({
    fullName: false,
    lastName: false,
    cellPhone: false,
    email: false
  });

  const [disabledButton, setDisabledButton] = useState(false)

  useEffect(() => {
    apiGetTypeDocuments({ codeForm: 'DpfForm' })
      .then((response) => response.json())
      .then((data) =>
        setListDocumentType(
          data.data.map((item: DocumentType) => ({
            value: item.codeTypeDoc,
            name: item.documentType,
          }))
        )
      )
      .catch((err) => console.log(err));
    apiGetFindUbigeoDep()
      .then((response) => response.json())
      .then((data) =>
        setListDepartment(
          data.result.map((item: ListDepartment) => ({
            value: item.codeDep,
            name: item.departament,
          }))
        )
      )
      .catch((err) => console.log(err));

    return () => {};
  }, []);

  const envioFormulario = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleModalToastSuccessClose();
    const request = {
      fullName: firstName.trim(),
      lastName: lastName.trim(),
      typeDocument: typeDocument?.name,
      document: document,
      phone: phone,
      email: email,
      amount: amount,
      department: department?.name.trim(),
      province: province?.name.trim(),
      district: district?.name.trim(),
      readAndAcceptedPolicy: checkAccordance,
      acceptedDataProcessingAgreement: checkPolitics
    };

    const noErrors = Object.values(validateFormError).every(x => !x);
    const { complete } = validateFormDPF(request);
    
    if (complete && checkAccordance && checkPolitics && noErrors && captchaRef) {
      setDisabledButton(true)
      apiPostSaveDpfForm(request)
        .then((response) => {
          setDisabledButton(false)
          if (response.status > 300) {
            let currentMessage: string[] = [];
            currentMessage.push("Se presentó un error en el envío de la solicitud");
            handleModalToastSuccessShow();
            setMessageErrorToast(currentMessage);
          } else {
            handleResetForm();
            redirectResponseForm();
            response.json();
          }
        })
        .catch((error) => 
          {
            SetMessagesError([{
              text: "Se presentó un error en el envío de la solicitud",
            }]);
            openSnackerrorFunction()
          }
        );
    } else {
      let currentMessage: string[] = [];
      if (!noErrors) {
        currentMessage.push(
          "Pendiente completar los campos correctamente."
        );
      }
      if (!complete) {
        currentMessage.push("Pendiente completar todos los campos correctamente.");
      }
      if (!checkPolitics) {
        currentMessage.push(
          "Pendiente aceptar la “Política de tratamiento de protección de datos personales”"
        );
      }
      if (!checkAccordance) {
        currentMessage.push("Pendiente dar conformidad de la solicitud ingresada");
      }
      if(!captchaRef) {
        currentMessage.push("Pendiente completar correctamente la verificación.");
      }

      setVisibleValidation(true);
      handleModalToastSuccessShow();
      setMessageErrorToast(currentMessage);
    }
  };

  const handleTypeDocument = (e: { value: string; name: string }) => {
    setTypeDocument(e);
    setDocument("");
    if (e.value === "02") {
      setTypeInput("text");
    }
    if (e.value === "01" || e.value === "03" || e.value === "04") {
      setTypeInput("number");
    }
  };

  const handleModalToastSuccessClose = () => {
    setVisibleModalToastSuccess(false);
  };

  const redirectResponseForm = () => {
    window.location.href = LinkRoutes.responseForm
  };

  const handleModalToastSuccessShow = () => {
    setVisibleModalToastSuccess(true);
  };

  const handleResetForm = () => {
    setVisibleValidation(false);
    setTypeDocument(null);
    setDocument("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setAmount('');
    setDepartment(null);
    setProvince(null);
    setDistrict(null);
    setCheckPolitics(false);
    setCheckAccordance(false);
    setCaptcha(null);
  };

  const onChangeDepartament = (value: { value: string; name: string }) => {
    setDepartment(value);
    setProvince(null);
    setDistrict(null);
    setListDistrict([]);

    const request = {
      codeDep: value.value,
      codeProv: "",
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListProvince(
          data.result.responseProvince.map((item: any) => ({
            value: item.codeProv,
            name: item.province,
          }))
        )
      )
      .catch((error) => console.log(error));

    setVisibleValidationProvincia(false);
    setVisibleValidationDistrito(false);
  };

  const onChangeProvince = (value: { value: string; name: string }) => {
    setProvince(value);
    const request = {
      codeDep: "",
      codeProv: value.value,
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListDistrict(
          data.result.responseDistrict.map((item: any) => ({
            value: item.codeDist,
            name: item.district,
          }))
        )
      )
      .catch((error) => console.log(error));
  };

  const onClickProvincia = () => {
    if (department === undefined || department === null) {
      setVisibleValidationProvincia(true);
    }
    setVisibleValidationDistrito(false);
  };

  const onClickDistrito = () => {
    if (province === undefined || province === null) {
      setVisibleValidationDistrito(true);
    }
  };

  const onChangeCaptcha = (val: string | null) => {
    setCaptcha(val);
  };

  
  return (
    <div className="container-body">

      <div>
        <BannerFormDpf />
      </div>
      <div className="center-div" >
        <div className="container-form" style={{ margin: "0 auto" }}>

          <Box
            component="form"
            onSubmit={(e: any) => envioFormulario(e)}
            autoComplete="off"
          >

            <div style={{ margin: "auto" }}>
              <div className="dpf-title">
                <span>Completa este formulario y nos contactaremos contigo</span>
              </div>
              <div className="dpf-subtitle">
                <span >Ingresa tus datos personales</span>
              </div>
              <div className="form-container-components">
                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    id="txtName"
                    name="input-name"
                    disabled={false}
                    dependValue={null}
                    key="txtName"
                    value={firstName}
                    setValue={setFirstName}
                    visibleError={visibleValidation}
                    label="Nombres"
                    fullWidth={true}
                    variant="filled"
                    validateError={(v) => {
                      let validate = validateLimitLetter(v);
                      setValidateError(prev => ({ ...prev, fullName: validate.error }))
                      return validate
                    }}
                    pattern={PATTER_ALPH}
                  />
                </div>
                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    id="txtLastName"
                    name="input-last-name"
                    disabled={false}
                    dependValue={null}
                    key="txtLastName"
                    value={lastName}
                    setValue={setLastName}
                    visibleError={visibleValidation}
                    label="Apellidos"
                    fullWidth={true}
                    variant="filled"
                    validateError={(v) => {
                      let validate = validateLimitLetter(v);
                      setValidateError(prev => ({ ...prev, lastName: validate.error }))
                      return validate
                    }}
                    pattern={PATTER_ALPH}
                  />
                </div>
              </div>
              <div className="form-container-components">
                <div
                  style={{ margin: "9px 0 8px 0" }}
                >
                  <SurgirDropDown
                    id="selectTypeDocument"
                    name="select-type-document"
                    hint={"Tipo de documento"}
                    classAutocomplete="w-100"
                    dataSource={listDocumentType}
                    error={
                      visibleValidation &&
                      validateFieldObject(typeDocument).error
                    }
                    messageError={validateFieldObject(typeDocument).message}
                    value={typeDocument}
                    onChange={handleTypeDocument}
                  />
                </div>

                <div

                  style={{ margin: "8px 0 8px 0" }}
                >
                  <SurgirTextField
                    id="txtNumberDocument"
                    name="input-number-document"
                    type=""
                    key="txtNumberDocument"
                    prefix=""
                    value={document}
                    visibleError={visibleValidation}
                    disabled={
                      typeDocument == undefined ||
                      typeDocument == null ||
                      typeDocument.value == ""
                    }
                    dependValue={typeDocument}
                    validateError={validateDocumentNumber}
                    setValue={setDocument}
                    label="Número de documento"
                    fullWidth={true}
                    variant="filled"
                    pattern={
                      typeDocument?.name == "DNI"
                        ? /^(\d{0,8})$/g : typeDocument?.value == "04" ? /^(\d{0,12})$/g : /^([a-zA-Z]|\d){0,12}$/g
                    }
                  />
                </div>
              </div>
              <div className="form-container-components">
                <div
                  style={{ margin: "8px 0 8px 0" }}
                >
                  <SurgirTextField
                    id="txtPhone"
                    name="input-phone"
                    disabled={false}
                    dependValue={null}
                    key="txtPhone"
                    value={phone}
                    setValue={(v) => {
                      if (v.length) {
                        /^9.*/g.test(v) && setPhone(v);
                      } else {
                        setPhone(v);
                      }
                    }}
                    visibleError={visibleValidation}
                    label="Celular de contacto"
                    fullWidth={true}
                    variant="filled"
                    validateError={(v) => {
                      let validate = validateCellPhone(v);
                      setValidateError(prev => ({ ...prev, cellPhone: validate.error }))
                      return validate
                    }}
                    pattern={/^\d{0,9}$/g}
                  />
                </div>


                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    id="txtEmail"
                    name="input-email"
                    disabled={false}
                    key="txtEmail"
                    value={email}
                    setValue={setEmail}
                    visibleError={visibleValidation}
                    label="Correo electrónico"
                    fullWidth={true}
                    variant="filled"
                    validateError={(v) => {
                      let validate = validateEmailv2(v);
                      setValidateError(prev => ({ ...prev, email: validate.error }))
                      return validate
                    }}
                    pattern={/[^\s]/}
                  />
                </div>
              </div>

              <div>
              <SurgirTextField
                id="txtAmount"
                name="input-amount"
                value={amount}
                label="Monto del depósito"
                fullWidth={true}
                variant="filled"
                type="text"
                validateError={validateFieldString}
                FormatCustom={true}
                setValue={setAmount}
                disabled={false}
                prefix={'S/ '}
                visibleError={visibleValidation}
                isAmountApertura={true}
              />
              </div>
              <div className="form-container-components-ubigeo">
                <div>
                <SurgirAutocomplete
                        error={
                          visibleValidation &&
                          validateFieldObject(department).error
                        }
                        messageError={validateFieldObject(department).message}
                        hint={"Departamento"}
                        value={department}
                        onChange={onChangeDepartament}
                        dataSource={listDepartment}
                        required={false}
                        search={"name"}
                        id={"selectDepartment"}
                        name={"select-department"}
                        classContainer="w-100"
                      />
                </div>


                <div>
                  <SurgirAutocomplete
                    error={
                      (visibleValidation || visibleValidationProvincia) &&
                      validateFieldObject(province).error
                    }
                    messageError={
                      visibleValidationProvincia
                        ? "Elige primero un departamento."
                        : validateFieldObject(province).message
                    }
                    hint={"Provincia"}
                    value={province}
                    onChange={(value) => {
                      onChangeProvince(value);
                      setDistrict(null);
                    }}
                    onClick={onClickProvincia}
                    dataSource={listProvince}
                    required={false}
                    search={"name"}
                    disabled={listProvince.length == 0}
                    id={"selectProvince"}
                    name={"select-province"}
                    classContainer="w-100"
                  />
                </div>
                <div>
                  <SurgirAutocomplete
                    error={
                      (visibleValidation || visibleValidationDistrito) &&
                      validateFieldObject(district).error
                    }
                    messageError={
                      visibleValidationDistrito
                        ? "Elige primero un departamento y una provincia."
                        : validateFieldObject(district).message
                    }
                    hint={"Distrito"}
                    value={district}
                    onChange={setDistrict}
                    onClick={onClickDistrito}
                    dataSource={listDistrict}
                    required={false}
                    disabled={listDistrict.length == 0}
                    search={"name"}
                    id={"selectDistrict"}
                    name={"select-district"}
                    classContainer="w-100"
                  />
                </div>
              </div>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <br />
                <Form.Check
                  id={"chkAccordance"}
                  checked={checkAccordance}
                  onChange={({ target }) =>
                    setCheckAccordance(!checkAccordance)
                  }
                  type="checkbox"
                  label={
                    <span className="parrafo">
                      <span>{" "}
                        Sí, he leído y presto mi consentimiento a los {" "}
                      </span>
                      <a
                        className="Links"
                        style={{
                          color: "#257fa4",
                          marginRight: "20px",
                          fontFamily: "Str-Headline-Bold",
                        }}
                        target="_blank"
                        href="/legales#terminos-y-condiciones"
                      >
                        Términos y Condiciones
                      </a>
                    </span>
                  }
                />
                <FormHelperText
                  error={true}
                  style={{
                    display: visibleValidation
                      ? checkAccordance
                        ? "none"
                        : "inherit"
                      : "none",
                    marginLeft: 40,
                  }}
                >
                  {" "}
                  Pendiente dar conformidad de la solicitud ingresada.{" "}
                </FormHelperText>
                <br />
                <Form.Check
                  id={"chkPolitics"}
                  checked={checkPolitics}
                  onChange={({ target }) => setCheckPolitics(!checkPolitics)}
                  type="checkbox"
                  label={
                    <span className="parrafo">
                      <span>Sí, he leído y acepto la </span>
                      <a
                        className="Links"
                        style={{
                          color: "#257fa4",
                          marginRight: "20px",
                          fontFamily: "Str-Headline-Bold",
                        }}
                        target="_blank"
                        href="/legales#tratamiento-datos"
                      >
                        {" "}
                        Política de tratamiento de protección de datos
                        personales.{" "}
                      </a>
                    </span>
                  }
                />
                <FormHelperText
                  error={true}
                  style={{
                    display: visibleValidation
                      ? checkPolitics
                        ? "none"
                        : "inherit"
                      : "none",
                    marginLeft: 40,
                  }}
                >
                  {" "}
                  Pendiente aceptar la “Política de tratamiento de protección de
                  datos personales”.{" "}
                </FormHelperText>
              </Form.Group>

              <div className="w-100 d-flex justify-content-center">
                <ReCAPTCHA 
                  style={{ textAlign: "center" }} 
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''} 
                  onChange={onChangeCaptcha} 
                />
              </div>

              <center>
                <div>
                  <Button
                    id="btnSendRequest"
                    style={{
                      width: 180,
                      borderRadius: 32,
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: "16",
                      height: 48,
                      textAlign: "center",
                      marginTop: 30,
                      marginBottom: 50,
                    }}
                    variant="danger"
                    type="submit"
                    disabled={disabledButton}
                  >
                    {" "}
                    Enviar consulta{" "}
                  </Button>
                </div>
                <ConditionalLink
                  show={showReturnSimulator}
                />
              </center>
            </div>
          </Box>

          <ToastError
            handleShow={() => { }}
            key=''
            show={visibleModalToastSuccess}
            handleClose={handleModalToastSuccessClose}
            messages={messageErrorToas}
          />

          <SurgirSnackbarContentWrapper
            open={openSnackError}
            onopen={setopenSnackError}
            messages={messagesError}
          />

        </div>
      </div>
    </div>

  );

}

export default ContactanosRCF;
